import React from 'react';
import { useMsal } from '@azure/msal-react';

import { AccessButton } from './AccessButton';
import { loginRequest } from '../../../config/authConfig';

const handleLogin = (instance) => {
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
};

export const SignInButton = () => {
  const { instance } = useMsal();

  return <AccessButton onClick={() => handleLogin(instance)}>Sign in</AccessButton>;
};
