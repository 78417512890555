import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import { DetailsText } from './DetailsText';
import { AgreementForm } from './AgreementForm';
import { ColorBanner } from '../../shared/ColorBanner';
import { Notice } from '../../shared/Notice';
import { ViewLoadError } from '../../shared/ViewLoadError';

import { useAgreement } from '../../../utils/hooks/useAgreement';

export const FrameworkAgreementDetails = () => {
  const { id: agreementId } = useParams();
  const {
    loading,
    result: { frameworkAgreement: fa = {} },
    loadingFiles,
    files,
    error,
  } = useAgreement({ agreementId });

  return error ? (
    <ViewLoadError {...{ error }} frameworkAgreement />
  ) : (
    <>
      <ColorBanner
        title="Agreement details"
        subtitle={fa.name || 'Unknown name'}
        loading={loading}
        noTitleLoading
      />
      {fa.status && fa.status !== 'not applied' && (
        <Notice
          message={
            fa.status === 'accepted'
              ? 'Your application for this framework agreement has been approved'
              : 'Your application for this framework agreement has been recieved is currently being reviewed'
          }
        />
      )}
      <Grid container>
        <Grid item xs={12} md={6}>
          <DetailsText loading={loading} result={fa} loadingFiles={loadingFiles} files={files} />
        </Grid>
        <Grid container item xs={12} md={6} align="center">
          <Grid item xs={12}>
            {!loading && fa.status !== 'accepted' && (
              <AgreementForm {...{ applied: fa.status && fa.status === 'applied' }} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
