import { useMemo } from 'react';

import { useRequest } from './useRequest';
import { getApiUrl } from '../utils';

export const useAgreements = ({ page, pageSize, onlyApproved = false }) => {
  const endpoint = useMemo(() => `${getApiUrl()}/agreements`, []);
  const args = useMemo(
    () => ({
      ...(pageSize ? { limit: pageSize } : {}),
      ...(page && pageSize ? { skip: page * pageSize } : {}),
      showAccepted: onlyApproved,
    }),
    [pageSize, page, onlyApproved],
  );
  return useRequest({
    endpoint,
    method: 'POST',
    args,
  });
};
