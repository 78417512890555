import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import * as R from 'ramda';
import { Grid, Typography, useTheme, alpha, useMediaQuery } from '@mui/material';

import { ApplyDealButton } from './ApplyDealButton';
import { ViewLoadError } from '../../shared/ViewLoadError';
import { ColorBanner } from '../../shared/ColorBanner';
import { Notices } from './Notices';

import { useContents } from './contents';
import { useDeal } from '../../../utils/hooks/useDeal';

const initialAlertState = { message: '', open: false };

export const DealDetails = () => {
  const theme = useTheme();
  const [alert, setAlert] = useState(initialAlertState);

  const [message, setMessage] = useState('');
  const handleComment = (event) => {
    setMessage(event.target.value);
  };

  const { id: dealId } = useParams();
  const { loading, result, loadingFiles, files, error: fetchError } = useDeal({ dealId });
  const mobileMode = useMediaQuery(theme.breakpoints.down('md'));
  const getColor = (index) => {
    const colors = [alpha(theme.palette.secondary.main, 0.15), theme.palette.background.main];
    if (mobileMode) return colors[R.modulo(index, 2)];
    return colors[R.modulo(Math.ceil(index / 2), 2)];
  };

  const onError = useCallback((error) => {
    setAlert({ message: error, open: true, variant: 'error' });
  }, []);

  const contents = useContents({ ...{ result, loading, loadingFiles, files, handleComment } });
  return fetchError ? (
    <ViewLoadError {...{ error: fetchError }} />
  ) : (
    <>
      <ColorBanner
        title={result.customer || 'Unknown customer'}
        subtitle={result.name || 'Unknown assignment name'}
        loading={loading}
      />
      <Notices {...{ result }} />
      <Grid container sx={{ mt: 3, pb: 4 }}>
        {contents.map((content, i) => {
          return (
            <Grid item xs={12} md={6} sx={{ p: 4, background: getColor(i) }} key={content.title}>
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                {content.title}
              </Typography>
              {content.content}
              {content.title === 'Register interest' && (
                <ApplyDealButton
                  dealId={Number(dealId)}
                  body={message}
                  status={result.status || 'loading'}
                  bidDue={result.lastDayForBid}
                  onError={onError}
                  loadingDeal={loading}
                />
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
