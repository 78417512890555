import React from 'react';
import { Grid, Typography, Container } from '@mui/material';

import { DealsTable } from './DealsTable';
import { ImageBanner } from '../../shared/ImageBanner';
import { NavigateButton } from '../../shared/NavigateButton';
import { appUrl } from '../../../utils/utils';

import Image from '../../../images/high-ten.jpg';

export const Deals = () => {
  return (
    <>
      <ImageBanner image={Image}>
        <Container>
          <Typography variant="h3" sx={{ pt: 4 }}>
            Assignments
          </Typography>
          <Grid container sx={{ mb: 4, pb: 4 }}>
            <Grid item xs={7}>
              <Typography variant="h6" sx={{ mt: 3 }}>
                You can register interest for an assignment up until its application due date. After
                applying, you will recieve a response before the bid due date.
              </Typography>
            </Grid>
            <Grid item xs={5} sx={{ borderLeft: 2, borderColor: 'lightGray', p: 2 }}>
              <Typography variant="body1" sx={{ mt: -1, mb: 2, fontWeight: 'bold' }}>
                Other pages
              </Typography>
              <NavigateButton to={appUrl('/framework-agreements')}>
                Framework Agreements
              </NavigateButton>
            </Grid>
          </Grid>
        </Container>
      </ImageBanner>
      <DealsTable />
    </>
  );
};
