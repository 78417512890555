import { useRef } from 'react';
import { TextField, InputAdornment, useTheme } from '@mui/material';
import { Search, Clear } from '@mui/icons-material';

export const SearchField = ({ setSearchText, sx }) => {
  const theme = useTheme();
  const onChange = (e) => {
    setSearchText(e.target.value);
  };

  const inputRef = useRef();
  const clear = () => {
    inputRef.current.value = '';
    setSearchText('');
  };

  return (
    <TextField
      {...{ inputRef }}
      onChange={onChange}
      placeholder="Search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search sx={{ color: theme.palette.primary.main, height: 22 }} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {inputRef.current && inputRef.current.value ? (
              <Clear
                onClick={clear}
                sx={{
                  color: theme.palette.secondary.main,
                  height: 18,
                  cursor: 'pointer',
                  '&:hover': { color: theme.palette.primary.main },
                }}
              />
            ) : (
              <Clear sx={{ color: 'transparent' }} />
            )}
          </InputAdornment>
        ),
      }}
      sx={{
        justifyContent: 'center',
        '.MuiOutlinedInput-root': {
          borderRadius: '20px',
          height: 26,
          fontSize: 13,
          minWidth: 140,
          color: theme.palette.primary.main,
        },
        ...sx,
      }}
    />
  );
};
