import { useState, useEffect, forwardRef } from 'react';
import * as R from 'ramda';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Grid,
  Snackbar,
  IconButton,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { Close } from '@mui/icons-material/';

import { Background } from './Background';
import { RegisterForm } from './RegisterForm';
import { RegisteredNotice } from './RegisteredNotice';
import { RegisterRequestButton } from './RegisterRequestButton';
import { TooltipIcon } from './TooltipIcon';
import { useRegister } from '../../../utils/hooks/useRegister';

import Image from '../../../images/get-together-downsized.jpg';

const Alert = forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Register = () => {
  const theme = useTheme();
  const mobileMode = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const openSnackBar = () => {
    setSnackBarOpen(true);
  };
  const closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
  };

  const { control, handleSubmit, watch } = useForm();
  const body = watch();
  const [sent, setSent] = useState(false);

  const onSuccess = () => setSent(true);
  const { loading: sending, result, triggerFunc } = useRegister({ body });

  useEffect(() => {
    const onError = (message) => {
      setSnackBarMessage(message && message);
      openSnackBar();
    };
    if (!R.isEmpty(result)) {
      const { ok, status: responseStatus, statusText } = result;
      if (!ok && onError) onError(`Registration failed: ${responseStatus} ${statusText}`);
      else if (ok === true) {
        if (onSuccess) onSuccess();
      }
    }
  }, [result]);

  const onSubmit = () => triggerFunc();
  const width = 400;

  return (
    <>
      <Background
        fixed={!mobileMode}
        image={Image}
        variant="left"
        surface={`linear-gradient(-90deg, ${theme.palette.white.main} 0%, rgba(255,255,255,0.05) 45%)`}
        background={theme.palette.white.main}
      >
        <Grid
          container
          alignItems="center"
          sx={{
            position: 'absolute',
            width: mobileMode ? '100%' : '50%',
            left: mobileMode ? '0%' : '50%',
            top: '50%',
            transform: 'translate(0, -50%)',
            height: '100%',
          }}
        >
          {mobileMode && (
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                height: '20vh',
                width: '100vw',
                backgroundImage: `url(${Image})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  mt: 1,
                  background: `linear-gradient(0deg, rgba(255,255,255,1) 5%,rgba(255,255,255,0.1) 80%)`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            </Box>
          )}
          <Grid container item xs={12} sx={{ pl: 2, pr: 2, mt: mobileMode ? 12 : 0 }}>
            <Grid item align="center" xs={12}>
              <Box
                sx={{ maxWidth: width }}
                display={sent ? 'none' : 'block'}
                component="form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid item align="left" xs={12} sx={{ mb: 4 }}>
                  <Typography variant="body1" color={theme.palette.text.gray600} sx={{ mb: 2 }}>
                    We&apos;re happy you&apos;re joining us.
                  </Typography>
                  <Box>
                    <Typography display="inline" variant="h4" color={theme.palette.black.main}>
                      Register your account
                    </Typography>
                    <TooltipIcon title="Registered users can view and apply for framework agreements and deals." />
                  </Box>
                </Grid>
                <RegisterForm {...{ control }} />
                <Grid item xs={12}>
                  <RegisterRequestButton {...{ sending }} type="submit" />
                </Grid>
              </Box>
              <RegisteredNotice {...{ sent, width }} />
            </Grid>
          </Grid>
        </Grid>
      </Background>

      <IconButton
        onClick={() => navigate('/')}
        size="small"
        sx={{
          position: 'fixed',
          top: mobileMode ? 15 : 55,
          right: mobileMode ? 15 : 75,
          color: theme.palette.text.gray700,
          background: mobileMode ? '#fff' : 'rgba(0,0,0,0.08)',
        }}
      >
        <Close />
      </IconButton>
      <Snackbar open={snackBarOpen} autoHideDuration={5000} onClose={closeSnackBar}>
        <Alert onClose={closeSnackBar} severity="error" sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
