import React, { useState } from 'react';
import { Card as MuiCard, Grid, Typography, styled, useTheme } from '@mui/material';
import { ArrowRightAlt } from '@mui/icons-material';

const Card = styled(MuiCard)(({ theme }) => ({
  height: 340,
  width: 330,
  borderBottomLeftRadius: 90,
  borderBottomRightRadius: 0,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 90,
  cursor: 'pointer',
  transition: 'background 0.25s ease-in, color 0.3s ease-in, width ease 0.2s',
  display: 'flex',
  flexDirection: 'column',

  '& .arrowIcon': {
    color: theme.palette.lollipop.main,
    transition: 'background 0.25s ease-in, color 0.3s ease-in, width ease 0.2s',
    transform: 'scale(2)',
    width: '100%',
    marginBottom: '-6px',
  },

  '&:hover': {
    '& .arrowIcon': {
      width: '150%',
    },
  },
}));

export const InfoCard = ({
  title,
  description,
  navigationText = 'Read more',
  background,
  ...other
}) => {
  const theme = useTheme();
  const [shadow, setShadow] = useState(6);
  return (
    <Card
      elevation={shadow}
      onMouseOver={() => setShadow(1)}
      onMouseOut={() => setShadow(6)}
      sx={{ transition: 'all 0.2s linear', background }}
      {...other}
    >
      <Grid container sx={{ height: 340 }}>
        <Grid container item xs={12} sx={{ p: 3 }}>
          <Grid item sx={{ mt: 4 }}>
            <Typography variant="h5" component="div">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="span" variant="body1" color={theme.palette.text.gray800}>
              {description}
            </Typography>
          </Grid>
          <Grid container item sx={{ flex: 1, textAlign: 'right' }}>
            <Grid item xs={10} sx={{ height: 30 }}>
              <Typography variant="body1" display="inline" sx={{ mr: 1 }}>
                {navigationText}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <ArrowRightAlt className="arrowIcon" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
