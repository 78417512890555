import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Card as MuiCard, Grid, Typography, styled } from '@mui/material';

const Card = styled(MuiCard)(() => ({
  height: 340,
  maxWidth: 660,
  borderBottomLeftRadius: 90,
  borderBottomRightRadius: 0,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 90,
}));

export const ImageCard = ({ title, description, action, image, sx, ...other }) => {
  const theme = useTheme();

  return (
    <Card sx={sx} {...other}>
      <Grid container>
        <Grid item xs={6}>
          <Box sx={{ p: 3, height: 340 }}>
            <Typography variant="h5" component="div" sx={{ mt: 3 }}>
              {title}
            </Typography>
            <Typography variant="body1" color={theme.palette.text.gray800} sx={{ mt: 2 }}>
              {description}
            </Typography>
            {action}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'inline-block',
              height: '100%',
              backgroundImage: `url(${image})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              borderRadius: 0,
              width: '100%',
            }}
          />
        </Grid>
      </Grid>
    </Card>
  );
};
