import { useState } from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, useTheme } from '@mui/material';
import { ExpandMore } from '@mui/icons-material/';

const faqs = [
  {
    key: 0,
    q: 'What happens after I have registered interest for an assignment?',
    a: 'We look at the applications and choose the one that can work together with Knowit to win the assignment. All applicants will be contacted by Knowit.',
  },
  {
    key: 1,
    q: 'In the assignment that I´m interested in applying for, I see a note in the top left corner that says “Reserved”, what does it mean?',
    a: 'It means that someone had contact with the customer about the assignment before the assignment reached us and that the customer wants this delivery. In this case we will answer with that company.',
  },
  {
    key: 2,
    q: 'How can I share an interesting assignment with my colleague?',
    a: 'Copy the address for example https://businessportal.knowit.eu/assignments/8934503318 and send it to you colleague, when he/she is logged in she can paste it in the address bar in the browser.',
  },
  {
    key: 3,
    q: 'What happens if I want to apply for an assignment and the application due date has passed?',
    a: 'In the first place we want the applications before Application due date, so we have the time to do a good work with sending the tender to the customer. If you have a good offer please apply for the assignment anyway. We will help you but can´t make any promises due to the time left.',
  },
];

export const Faq = ({ sx }) => {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const theme = useTheme();
  return (
    <>
      {faqs.map((faq) => (
        <Accordion
          square
          sx={{ ...sx, mb: 2 }}
          expanded={expanded === faq.key}
          onChange={handleChange(faq.key)}
          key={faq.key}
        >
          <AccordionSummary expandIcon={<ExpandMore />} sx={{ p: 2 }}>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              {`Q: ${faq.q}`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              borderTop: `1px solid ${theme.palette.divider}`,
              p: 2,
              wordWrap: 'break-word',
            }}
          >
            <Typography>{`A: ${faq.a}`}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      <Typography sx={{ mt: 4, color: theme.palette.black.main }}>
        Can&apos;t find what you&apos;re looking for? Try contacting us instead.
      </Typography>
    </>
  );
};
