import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton, Button, useTheme } from '@mui/material';
import { SearchOff, UnfoldMore, ErrorOutline } from '@mui/icons-material';

import { NotFound } from './NotFound';
import { ErrorDialog } from './ErrorDialog';

export const ViewLoadError = ({ error, frameworkAgreement }) => {
  const { id } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const openDialog = () => {
    setOpen(true);
  };
  return error.response ? (
    error.response.status === 404 ? (
      <NotFound
        title={`The ${
          frameworkAgreement ? 'framework agreement' : 'assignment'
        } could not be found`}
        subtitle={
          <>
            Please make sure the ID you provided (<span style={{ fontWeight: 'bold' }}>{id}</span>)
            is correct.
          </>
        }
        icon={<SearchOff sx={{ width: 100, height: 100, color: theme.palette.primary.main }} />}
      >
        <Button
          sx={{ borderRadius: 20, mt: 2, mb: 1 }}
          variant="contained"
          onClick={() => navigate('/')}
        >
          Go to Home
        </Button>
        <Button sx={{ borderRadius: 20, fontWeight: 'bold' }} onClick={() => navigate(-1)}>
          Return to your previous page
        </Button>
      </NotFound>
    ) : (
      <NotFound
        title={`${error.response.status} ${error.response.statusText}`}
        subtitle={`An error occured while trying to load the ${
          frameworkAgreement ? 'framework agreement' : 'assignment'
        }.`}
        icon={<ErrorOutline sx={{ width: 100, height: 100, color: theme.palette.primary.main }} />}
      >
        <IconButton size="large" sx={{ color: theme.palette.primary.main }} onClick={openDialog}>
          <UnfoldMore sx={{ fontSize: 28 }} />
        </IconButton>
        <ErrorDialog {...{ error, open, setOpen }} />
      </NotFound>
    )
  ) : (
    <NotFound
      title="An unknown error occured"
      subtitle={`An unknown error occured while trying to load the ${
        frameworkAgreement ? 'framework agreement' : 'assignment'
      }.`}
      icon={<ErrorOutline sx={{ width: 100, height: 100, color: theme.palette.primary.main }} />}
    />
  );
};
