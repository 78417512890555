import React, { useEffect } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { CircularProgress, Button, useTheme } from '@mui/material';

import { useCancelFA } from '../../../utils/hooks/useCancelFA';

export const CancelFAButton = ({ agreementId, onSuccess, onError, sx }) => {
  const theme = useTheme();
  const { loading: canceling, result, triggerFunc } = useCancelFA({ agreementId });

  useEffect(() => {
    if (!R.isEmpty(result)) {
      const { ok, status: responseStatus, statusText } = result;
      if (!ok && onError)
        onError(`Failed to cancel your application: ${responseStatus} ${statusText}`);
      else if (ok === true) {
        if (onSuccess) onSuccess();
      }
    }
  }, [result, onSuccess, onError]);

  return (
    <Button
      sx={{ position: 'relative', height: 40, width: '50%', m: 1, mt: 0, ...sx }}
      variant="contained"
      text="Cancel application"
      onClick={triggerFunc}
      disabled={canceling}
    >
      Cancel
      {canceling && (
        <CircularProgress
          size={22}
          thickness={6}
          sx={{
            color: theme.palette.primary.main,
            position: 'absolute',
            top: '25%',
            left: 'calc(50% - 12px)',
          }}
        />
      )}
    </Button>
  );
};

CancelFAButton.propTypes = {
  agreementId: PropTypes.number,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};
