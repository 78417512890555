import { useTheme } from '@mui/material';

export const UpperChipNotes = ({ notes }) => {
  const theme = useTheme();
  return (
    <div>
      {notes.map((note) => (
        <span
          key={note.content}
          style={{
            backgroundColor: note.type
              ? note.type === 'create_proposal'
                ? theme.palette.flamingo.dark
                : theme.palette.leather.main
              : note.warning
              ? theme.palette.error.main
              : theme.palette.primary.main,
            color: theme.palette.white.main,
            borderRadius: '6px',
            paddingLeft: '4px',
            paddingRight: '4px',
            paddingBottom: '1px',
            marginRight: '2px',
          }}
        >
          {note.content}
        </span>
      ))}
    </div>
  );
};
