import { useState } from 'react';
import { Tooltip, ClickAwayListener, useTheme } from '@mui/material';
import { Help } from '@mui/icons-material/';

export const TooltipIcon = ({ title }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const toggleTooltipOpen = () => {
    if (!open) {
      setOpen(true);
    } else setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={title}
      >
        <Help
          onClick={toggleTooltipOpen}
          display="flex"
          sx={{
            cursor: 'pointer',
            ml: 1.5,
            mb: -0.5,
            color: theme.palette.primary.main,
          }}
        />
      </Tooltip>
    </ClickAwayListener>
  );
};
