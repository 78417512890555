import { Card as MuiCard, Container, Typography, Skeleton, styled, useTheme } from '@mui/material';

const Card = styled(MuiCard)(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: 0,
  width: '100vw',
  height: { xs: 60, md: 100 },
  marginLeft: 'calc(50% - 50vw)',
}));

export const ColorBanner = ({
  title,
  subtitle,
  loading,
  noTitleLoading,
  noSubtitleLoading,
  color,
  disableGutters,
  sx,
  children,
  ...other
}) => {
  const theme = useTheme();
  return (
    <Card elevation={0} position="fixed" sx={{ mb: 2, ...sx }} {...other}>
      <Container>
        {title && (
          <Typography
            variant="h3"
            sx={{
              pt: { xs: disableGutters || 2, md: disableGutters || 3 },
              color: color || theme.palette.white.main,
            }}
          >
            {loading && !noTitleLoading ? <Skeleton width="45%" /> : title}
          </Typography>
        )}

        {subtitle && (
          <Typography
            variant="h6"
            sx={{
              mt: { xs: 1, md: 2 },
              pb: { xs: 1, md: 3 },
              color: color || theme.palette.white.main,
            }}
          >
            {loading && !noSubtitleLoading ? <Skeleton width="30%" /> : subtitle}
          </Typography>
        )}
      </Container>
      {children}
    </Card>
  );
};
