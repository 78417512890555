import { Checkbox } from '@mui/material';
import { Controller } from 'react-hook-form';

export const FormCheckbox = ({ label, control, defaultChecked = false, ...other }) => {
  return (
    <Controller
      name={label}
      control={control}
      render={({ field }) => <Checkbox defaultChecked={defaultChecked} {...field} {...other} />}
    />
  );
};
