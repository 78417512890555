import * as React from 'react';
import {
  Menu as MuiMenu,
  Divider,
  Avatar,
  MenuItem,
  ListItemIcon,
  IconButton,
  Typography,
  Stack,
  useTheme,
} from '@mui/material';
import { Menu as MenuIcon, Logout } from '@mui/icons-material';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';

const handleLogout = (instance) => {
  instance.logoutRedirect().catch((e) => {
    console.error(e);
  });
};

export const Menu = () => {
  const theme = useTheme();

  const { instance, accounts } = useMsal();
  const name = accounts[0] ? accounts[0].name : 'Unknown User';

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        edge="start"
        color="inherit"
        sx={{ mr: -2.5 }}
      >
        <MenuIcon fontSize="large" />
      </IconButton>
      <MuiMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'hidden',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            minWidth: 240,
            '& .MuiAvatar-root': {
              background: theme.palette.primary.main,
              width: 26,
              height: 26,
              ml: 0.5,
              mr: 1.5,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <AuthenticatedTemplate>
          <Stack direction="row" sx={{ p: 1 }}>
            <Avatar />
            <Typography variant="body1">{name}</Typography>
          </Stack>
          <Divider />
          <MenuItem
            onClick={() => handleLogout(instance)}
            sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Sign out
          </MenuItem>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <Stack direction="row" sx={{ p: 1 }}>
            <Avatar sx={{ mt: -0.2 }} />
            <Typography variant="body1">Guest</Typography>
          </Stack>
          <Divider sx={{ mb: 1 }} />
        </UnauthenticatedTemplate>

        <Typography
          display="flex"
          sx={{
            fontSize: 10,
            mr: 2,
            color: theme.palette.text.gray600,
            justifyContent: 'flex-end',
          }}
        >
          {`Version ${window.versionInfo.app}`}
        </Typography>
      </MuiMenu>
    </>
  );
};
