import { useEffect, useState, useRef } from 'react';
import * as R from 'ramda';

export const useFilteredData = (data, searchText, rowEvolveMap = {}) => {
  const [filteredData, setFilteredData] = useState(data);
  const rowEvolveMapRef = useRef(rowEvolveMap);
  useEffect(() => {
    setFilteredData(
      searchText
        ? R.filter((row) =>
            R.includes(
              searchText.toLowerCase(),
              R.pipe(R.evolve(rowEvolveMapRef.current), R.values, R.join(' '), R.toLower)(row),
            ),
          )(data)
        : data,
    );
  }, [searchText, data, rowEvolveMapRef]);
  return filteredData;
};
