import React from 'react';
import { Grid, Typography, Container } from '@mui/material';

import { FATable } from './FATable';
import { ImageBanner } from '../../shared/ImageBanner';
import { NavigateButton } from '../../shared/NavigateButton';
import { appUrl } from '../../../utils/utils';

import Image from '../../../images/welcome.jpg';

export const FrameworkAgreements = () => {
  return (
    <>
      <ImageBanner image={Image}>
        <Container>
          <Typography variant="h3" sx={{ pt: 4 }}>
            Framework Agreements
          </Typography>
          <Grid container sx={{ mb: 4, pb: 4 }}>
            <Grid item xs={7}>
              <Typography variant="h6" sx={{ mt: 3 }}>
                Before you can see any assignments you need to have atleast one approved framework
                agreement.
              </Typography>
            </Grid>
            <Grid item xs={5} sx={{ borderLeft: 2, borderColor: 'lightGray', p: 2 }}>
              <Typography variant="body1" sx={{ mt: -1, mb: 2, fontWeight: 'bold' }}>
                Other pages
              </Typography>
              <NavigateButton to={appUrl('/assignments')}>Assignments</NavigateButton>
            </Grid>
          </Grid>
        </Container>
      </ImageBanner>

      <FATable />
    </>
  );
};
