import { useState } from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { ErrorOutline, UnfoldMore } from '@mui/icons-material/';

import { ErrorDialog } from '../ErrorDialog';

export const TableErrorOverlay = ({ error, color }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const openDialog = () => {
    setOpen(true);
  };
  return error ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        textAlign: 'center',
        height: '100%',
      }}
    >
      <Box sx={{ zIndex: 5 }}>
        <ErrorOutline
          sx={{ color: color || theme.palette.secondary.main, width: 65, height: 65 }}
        />
        <Typography sx={{ color: color || theme.palette.secondary.main }}>
          {error.response ? 'An error occurred' : 'An unknown error occurred'}
        </Typography>
        {error.response && (
          <>
            <Box>
              <Typography sx={{ mt: 1, color: color || theme.palette.secondary.main }} variant="h6">
                {`${error.response.status} ${error.response.statusText}`}
              </Typography>
              <IconButton
                size="small"
                sx={{
                  alignSelf: 'center',
                  color: color || theme.palette.secondary.main,
                }}
                onClick={openDialog}
              >
                <UnfoldMore sx={{ fontSize: 22, alignSelf: 'center' }} />
              </IconButton>
            </Box>
            <ErrorDialog {...{ error, open, setOpen }} />
          </>
        )}
      </Box>
    </Box>
  ) : (
    ''
  );
};
