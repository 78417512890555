import React, { useState } from 'react';
import { Box, Button as MuiButton, Zoom, Fade, styled } from '@mui/material';
import { Check, Clear } from '@mui/icons-material/';

const Button = styled(MuiButton)(({ theme }) => ({
  height: 42,
  borderRadius: 25,
  background: theme.palette.primary.main,
  '&:hover': {
    background: theme.palette.primary.main,
  },
}));

export const ChipButton = ({
  text,
  hoverText,
  icon,
  hoverIcon,
  disableIcon,
  width,
  sx,
  ...other
}) => {
  const [hover, setHover] = useState(false);
  return (
    <Button
      sx={{ ...sx }}
      {...other}
      startIcon={
        !disableIcon && (
          <Box sx={{ position: 'relative', height: 25, width: 25 }}>
            <Box sx={{ position: 'absolute' }}>
              <Zoom in={!hover} timeout={{ enter: 800, exit: 200 }}>
                {icon || <Check />}
              </Zoom>
            </Box>
            <Box sx={{ position: 'absolute' }}>
              <Zoom in={hover} timeout={{ enter: 800, exit: 200 }}>
                {hoverIcon || <Clear />}
              </Zoom>
            </Box>
          </Box>
        )
      }
      variant="contained"
      onMouseEnter={() => setHover(hoverText && true)}
      onMouseLeave={() => setHover(false)}
    >
      <Box
        display="flex"
        justifyContent="center"
        sx={{
          position: 'relative',
          height: 22,
          width: (disableIcon && width + 29) || width,
          ml: -0.5,
        }}
      >
        <Fade in={!hoverText || !hover} timeout={{ enter: 500, exit: 0 }}>
          <Box sx={{ position: 'absolute' }}>{text}</Box>
        </Fade>

        <Fade in={hoverText && hover} timeout={{ enter: 500, exit: 0 }}>
          <Box sx={{ position: 'absolute' }}>{hoverText}</Box>
        </Fade>
      </Box>
    </Button>
  );
};
