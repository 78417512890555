import { Typography, Skeleton, Stack, TextField } from '@mui/material';

import { DealDetailsList } from './DealDetailsList';
import { FileDownload } from '../../shared/FileDownload';

import { valueOrFalse } from '../../../utils/utils';

export const useContents = ({ result, loading, loadingFiles, files, handleComment }) => [
  {
    title: 'Details',
    content: <DealDetailsList data={result} loading={loading} />,
  },
  {
    title: 'Description',
    content: loading ? (
      <Stack spacing={1} sx={{ mt: 2 }}>
        <Skeleton />
        <Skeleton width="85%" />
        <Skeleton width="95%" />
        <Skeleton width="70%" />
      </Stack>
    ) : (
      <Typography sx={{ p: 2, pl: 0, whiteSpace: 'pre-line' }} style={{ wordWrap: 'break-word' }}>
        {result.description || 'There is no description.'}
      </Typography>
    ),
  },
  {
    title: 'Tender files',
    content: loadingFiles ? (
      <Stack spacing={3} sx={{ mt: 2 }}>
        <Skeleton width="45%" />
        <Skeleton width="35%" />
        <Skeleton width="40%" />
      </Stack>
    ) : files.length > 0 ? (
      <>
        {valueOrFalse(result.tenderDocumentsLink) && (
          <Typography sx={{ mt: 2 }} style={{ wordWrap: 'break-word' }}>
            {result.tenderDocumentsLink}
          </Typography>
        )}
        {files.map((file, i) => (
          <FileDownload
            key={`${file.name + i}`}
            fileName={file.name}
            url={file.url}
            onError={() => console.log('FileDownload had an error')}
          />
        ))}
      </>
    ) : (
      <Typography sx={{ mt: 2 }}>There are no files.</Typography>
    ),
  },
  {
    title: 'Register interest',
    content: (
      <>
        <Typography sx={{ pt: 2, whiteSpace: 'pre-line' }}>
          After registration, we will contact you. {'\n\n'} If there are more than one interested
          applicant, we will evaluate which one is more qualified.
        </Typography>
        <TextField
          label="Additional information (optional)"
          multiline
          rows={4}
          fullWidth
          size="small"
          onChange={handleComment}
          sx={{ mt: 3 }}
          disabled={valueOrFalse(result.status) === 'applied'}
        />
        {valueOrFalse(result.interestedParties) && result.interestedParties.length > 0 && (
          <Typography sx={{ pt: 2, whiteSpace: 'pre-line' }}>
            Note that these parties: {result.interestedParties.join(', ')}, has already shown
            interest in the assignment.
          </Typography>
        )}
      </>
    ),
  },
];
