import { useMemo } from 'react';

import { useRequest } from './useRequest';
import { getApiUrl } from '../utils';

export const useApplyDeal = ({ dealId, body = '' }) => {
  const endpoint = useMemo(() => `${getApiUrl()}/deals/${dealId}/apply`, [dealId]);
  return useRequest({
    endpoint,
    method: 'POST',
    externalTrigger: true,
    body,
  });
};
