import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Box } from '@mui/material';
import { isAfter, parseISO } from 'date-fns';

import { useApplyDeal } from '../../../utils/hooks/useApplyDeal';
import { CancelDealButton } from './CancelDealButton';

export const ApplyDealButton = ({
  dealId,
  body,
  onSuccess,
  onError,
  status,
  loadingDeal,
  bidDue,
}) => {
  const [registered, setRegistered] = useState(false);
  const { loading: applying, result, triggerFunc } = useApplyDeal({ dealId, body });

  const dayAfterBidDue = bidDue ? new Date(parseISO(bidDue).getTime() + 86400000) : null;
  const overdue = isAfter(new Date(), dayAfterBidDue);
  useEffect(() => {
    if (!R.isEmpty(result)) {
      const { ok, status: responseStatus, statusText } = result;
      if (!ok && onError) onError(`Apply failed: ${responseStatus} ${statusText}`);
      else if (ok === true) {
        setRegistered(true);
        if (onSuccess) onSuccess();
      }
    }
  }, [result, onSuccess, onError]);

  return registered || status === 'applied' ? (
    <CancelDealButton {...{ dealId, onError }} />
  ) : (
    <Box sx={{ position: 'relative', mb: 4, mt: 4, width: 190, height: 40 }}>
      <Button
        variant="contained"
        sx={{ width: 190, height: 40, borderRadius: 6 }}
        disabled={applying || loadingDeal || overdue}
        onClick={triggerFunc}
      >
        {applying ? 'Sending application' : 'Register interest'}
      </Button>
      {applying && (
        <CircularProgress
          size={22}
          thickness={6}
          sx={{
            position: 'absolute',
            top: '25%',
            left: '43%',
          }}
        />
      )}
    </Box>
  );
};

ApplyDealButton.propTypes = {
  dealId: PropTypes.number,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};
