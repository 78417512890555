import { Button as MuiButton, Link, styled } from '@mui/material';

export const Button = styled(MuiButton)(({ theme }) => ({
  transition: 'background 0.25s ease-in, color 0.3s ease-in',
  color: theme.palette.white.main,
  background: theme.palette.primary.main,
  disableRipple: true,
  borderRadius: 25,
  borderTopLeftRadius: 0,
  borderWidth: '2px',
  textTransform: 'none',
  fontSize: 14,
  padding: 10,
  height: 45,
  minWidth: 120,
  paddingLeft: 20,
  paddingRight: 20,
  fontWeight: 'bold',
  flex: 0,
  width: 'auto',
  '&:hover': {
    background: theme.palette.flamingo.dark,
    color: theme.palette.black.main,
  },
}));

export const NavigateButton = ({ to, children }) => (
  <Link underline="none" href={to} rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}>
    <Button sx={{ flexGrow: 0, boxShadow: 4 }}>{children}</Button>
  </Link>
);
