import { useState } from 'react';
import { Card, alpha, styled, useTheme } from '@mui/material';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';

import { TableToolbar } from './TableToolbar';
import { TableErrorOverlay } from './TableErrorOverlay';

import { useFilteredData } from '../../../utils/hooks/useFilteredData';

const DataGrid = styled(MuiDataGrid)(({ clickable = false, theme }) => ({
  background: theme.palette.white.main,
  borderRadius: 0,
  '.MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '.MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-row:not(:hover)': {
    '&:nth-of-type(2n)': {
      backgroundColor: alpha(theme.palette.primary.main, 0.07),
    },
  },
  '& .MuiDataGrid-row': {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      cursor: clickable ? 'pointer' : 'default',
    },
  },
}));

export const Table = ({
  title,
  data,
  searchRowEvolveMap,
  filterSwitchSearchText,
  filterSwitchLabel,
  cols,
  page,
  setPage,
  size,
  setSize,
  error,
  ...other
}) => {
  const theme = useTheme();

  const [switchValue, setSwitchValue] = useState(false);
  const onSwitchChange = (event) => setSwitchValue(event.target.checked);

  const [searchText, setSearchText] = useState();
  const switchFilteredData = useFilteredData(data, switchValue ? filterSwitchSearchText || '' : '');

  const filteredData = useFilteredData(switchFilteredData, searchText, searchRowEvolveMap);
  return (
    <Card
      sx={{
        background: theme.palette.primary.main,
        borderRadius: 0,
        display: 'flex',
        height: '100%',
      }}
      elevation={2}
    >
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          autoHeight={!error}
          rows={filteredData}
          columns={cols}
          page={page}
          onPageChange={setPage}
          pageSize={size}
          onPageSizeChange={setSize}
          density="comfortable"
          rowsPerPageOptions={[10, 25, 50, 75, 100]}
          onCellClick={(params) => {
            window.open(`${window.location}/${params.id}`);
          }}
          initialState={{
            pagination: {
              pageSize: 50,
            },
          }}
          getRowClassName={(params) => {
            return params.row.reserved ? 'reserved' : '';
          }}
          components={{
            Toolbar: TableToolbar,
            NoRowsOverlay: error && TableErrorOverlay,
          }}
          componentsProps={{
            toolbar: { setSearchText, onSwitchChange, filterSwitchSearchText, filterSwitchLabel },
            noRowsOverlay: { error },
          }}
          sx={{ minHeight: error ? 400 : undefined }}
          {...other}
        />
      </div>
    </Card>
  );
};
