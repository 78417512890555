import { useMemo } from 'react';

import { useRequest } from './useRequest';
import { getApiUrl } from '../utils';

export const useDeals = ({ archived = false }) => {
  const endpoint = useMemo(() => `${getApiUrl()}/deals`, []);
  const args = useMemo(
    () => ({
      // Disabled while we are not using pagination through hubspot
      //  ...(pageSize ? { limit: pageSize } : {}),
      // ...(page && pageSize ? { skip: page * pageSize } : {}),
      archived,
    }),
    [archived],
  );
  return useRequest({
    endpoint,
    method: 'POST',
    args,
  });
};
