import { Grid, Box, Tooltip, Typography, Link } from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

export const FileDownload = ({ fileName, url }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Grid container sx={{ mb: 3 }}>
        <Grid item xs={2} sm={1}>
          <Tooltip title="Download" placement="right" enterDelay={500} enterNextDelay={500}>
            <Link href={url} target="_blank" rel="noopener noreferrer" download>
              <DownloadForOfflineIcon sx={{ ' &:hover': { transform: 'scale(1.35)' } }} />
            </Link>
          </Tooltip>
        </Grid>
        <Grid item xs={10} sm={11}>
          <Typography display="inline">{fileName}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
