import { Typography, Box, Zoom, Grow, useTheme } from '@mui/material';
import { CheckCircleOutlined } from '@mui/icons-material/';

import { CancelFAButton } from './CancelFAButton';

export const AppliedMessage = ({ agreementId, sent, setSent }) => {
  const theme = useTheme();
  return (
    <Box sx={{ width: '100%', pt: 2, pb: 2 }}>
      <Zoom in={sent} timeout={1000}>
        <CheckCircleOutlined sx={{ width: 100, height: 100, color: theme.palette.primary.main }} />
      </Zoom>
      <Grow in={sent} timeout={800}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Application have been sent!
        </Typography>
      </Grow>
      <CancelFAButton
        sx={{ mt: 2 }}
        {...{ agreementId }}
        onerror={() => console.log('Failed to cancel application')}
        onSuccess={() => setSent(false)}
      />
    </Box>
  );
};
