import React, { useCallback, useState, useEffect } from 'react';
import * as R from 'ramda';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, IconButton, useTheme, styled } from '@mui/material';
import { Delete } from '@mui/icons-material/';

const ParentBox = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  padding: 16,
  border: `1px dashed ${theme.palette.border.light}`,
  borderRadius: 4,
  '&:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
    '& .arrowIcon': {
      width: '50%',
    },
  },
}));

export const Dropzone = ({ name, setValue }) => {
  const theme = useTheme();
  const [myFiles, setMyFiles] = useState([]);

  const [text, setText] = useState(`${myFiles.length} selected file(s)`);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const uniqueFiles = R.uniqBy(R.prop('path'))([...myFiles, ...R.uniq(acceptedFiles)]);
      setMyFiles(uniqueFiles);
    },
    [myFiles],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
  });

  const removeFile = (file) => {
    const newFiles = R.remove(myFiles.indexOf(file), 1, myFiles);
    setMyFiles(newFiles);
    setText(`${myFiles.length} selected file(s)`);
  };

  useEffect(() => {
    setValue(name, myFiles);
    setText(`${myFiles.length} selected file(s)`);
  }, [name, setValue, myFiles]);

  return (
    <>
      <ParentBox
        sx={{ position: 'relative', mb: 2 }}
        {...getRootProps()}
        onMouseOver={() => setText('Click to browse or drag file')}
        onMouseOut={() => setText(`${myFiles.length} selected file(s)`)}
      >
        <input {...getInputProps()} />
        <Typography
          variant="body1"
          color={theme.palette.text.gray600}
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {isDragActive ? 'Drop to use this file' : text}
        </Typography>
      </ParentBox>

      {myFiles.map((file) => (
        <Box
          key={file.path}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Typography variant="body1" display="inline" sx={{ ml: '18px' }}>
            {file.path} - {file.size} bytes
          </Typography>
          <IconButton size="small" onClick={() => removeFile(file)}>
            <Delete sx={{ height: '18px', width: '18px' }} />
          </IconButton>
        </Box>
      ))}
    </>
  );
};
