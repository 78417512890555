import 'react-datepicker/dist/react-datepicker.css';
import { Link } from '@mui/material';

import { StatusChip } from './StatusChip';
import { UpperChipNotes } from './UpperChipNotes';
import { TableTooltip } from '../../shared/table/TableTooltip';
import { TableTimeLeft } from '../../shared/table/TableTimeLeft';
import { TableNote } from '../../shared/table/TableNote';
import { appUrl } from '../../../utils/utils';

export const dealsColumns = ({ archived = false, density } = {}) => {
  const iconSize = () => (density === 'compact' ? 14 : 18);
  const newNoteTopPos = () => (density === 'standard' ? -15 : -19);
  const sx = density === 'compact' ? { fontSize: 12, height: 20, width: 70 } : {};

  const newComparisonDate = new Date().setHours(0, 0, 0, 0) - 86400000;
  const isNew = (created) => newComparisonDate <= new Date(created);
  const notes = (params) => {
    const newRow = isNew(params.row.created);
    const proposalStage =
      params.row.stage === 'create_proposal'
        ? [{ type: 'create_proposal', content: 'Create proposal' }]
        : params.row.stage === 'proposal_presented'
        ? [{ type: 'proposal_presented', content: 'Proposal presented' }]
        : [];
    const otherStage =
      newRow && params.row.reserved
        ? [{ content: 'New!' }, { content: 'Reserved', warning: true }]
        : newRow
        ? [{ content: 'New!' }]
        : params.row.reserved
        ? [{ content: 'Reserved', warning: true }]
        : [];
    return [...proposalStage, ...otherStage];
  };

  return [
    {
      field: 'customer',
      headerName: 'Customer',
      width: 220,
      renderCell: (params) =>
        archived || density === 'compact' ? (
          params.value
        ) : (
          <TableNote
            value={<TableTooltip {...{ params }} />}
            note={<UpperChipNotes notes={notes(params)} />}
            sx={{ top: newNoteTopPos, left: 0 }}
          />
        ),
    },
    {
      field: 'name',
      headerName: 'Name',
      maxWidth: 420,
      minWidth: 140,
      flex: 1,
      renderCell: (params) => <TableTooltip {...{ params }} />,
    },
    {
      field: 'location',
      headerName: 'Location',
      width: 140,
      renderCell: (params) => <TableTooltip {...{ params }} />,
    },
    {
      field: 'workModel',
      headerName: 'Work model',
      width: 100,
    },
    {
      field: 'lastDayForBid',
      headerName: 'Bid due',
      width: 100,
    },
    {
      field: 'lastDayForApplication',
      headerName: 'Application due',
      width: 120,
      renderCell: (params) =>
        archived || density === 'compact' ? (
          params.value
        ) : (
          <TableTimeLeft daysToShow={3} daysForWarning={1} date={params.value} />
        ),
    },
    {
      field: 'frameworkAgreement',
      headerName: 'Framework agreement',
      width: 270,
      renderCell: (params) => (
        <Link
          href={appUrl(`/framework-agreements/${params.value.hubspotId}`)}
          onClick={(e) => e.stopPropagation()}
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {params.value.name}
        </Link>
      ),
      sortComparator: (value1, value2) => value1.name.localeCompare(value2.name),
    },
    {
      field: 'status',
      headerName: 'Applicants',
      width: 100,
      align: 'center',
      renderCell: (params) => StatusChip({ ...{ status: params.value, iconSize, sx } }),
    },
  ];
};
