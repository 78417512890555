import { useForm } from 'react-hook-form';
import { Typography, Button, Grid, useTheme } from '@mui/material';

import { FormText } from '../../shared/form-fields/FormText';

const formSegments = [
  {
    name: 'userInfo',
    fields: [
      { name: 'name', label: 'Name' },
      { name: 'phone', label: 'Phone number (optional)' },
      { name: 'email', label: 'E-mail' },
      { name: 'category', label: 'Category' },
    ],
  },
];

const teamsLink = `https://teams.microsoft.com/l/channel/19%3a2d91dfebb54d4e7b9522b3b96855c250%40\
thread.skype/Business%2520Portal?groupId=96a07534-35f7-4648-96d3-0c5788e41071&\
tenantId=6735929c-9dbf-473b-9fc6-5fbdcd2c9fc4`;

export const Contact = () => {
  const { control, handleSubmit } = useForm();
  const theme = useTheme();
  return (
    <>
      <Typography sx={{ mb: 2, mt: 0, color: theme.palette.black.main }}>
        If you need support or have a question and can´t find the answer in the FAQ below or have
        suggestions for improvement, use one of the contact alternatives below.
        <br />
        <br />
        Please include this application version in your message:{' '}
        <span style={{ fontWeight: 'bold' }}>{window.versionInfo.app}</span>
        <br />
        <br />
        Teams: <a href={teamsLink}>Business Portal</a>
        <br />
        Email: <a href="mailto: businessportal@knowit.eu">businessportal@knowit.eu</a>
      </Typography>

      {false && (
        <>
          <Typography variant="h5" color={theme.palette.text.gray800}>
            Send a message
          </Typography>
          <Typography variant="body2" color={theme.palette.text.gray800} sx={{ mb: 4 }}>
            (Currently disabled)
          </Typography>

          <Grid container sx={{ mb: 3, position: 'relative' }}>
            {formSegments.map((segment) => (
              <Grid container item xs={12} md={4} key={segment.name} sx={{ pr: { xs: 0, md: 4 } }}>
                {segment.fields.map((field) => (
                  <Grid item key={field.name} xs={12} sx={{ mb: 2 }}>
                    <FormText
                      {...field}
                      control={control}
                      sx={{ background: theme.palette.white.main, ...segment.sx }}
                      required
                      InputLabelProps={{ required: false }}
                    />
                  </Grid>
                ))}
              </Grid>
            ))}
            <Grid container item xs={12} md={8} lg={6} sx={{ mb: 2 }}>
              <FormText
                name="description"
                label="Describe your problem"
                multiline
                rows={8.35}
                control={control}
                sx={{ background: theme.palette.white.main }}
                required
                InputLabelProps={{ required: false }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={4} sx={{ pr: { xs: 0, md: 4 } }}>
              <Button sx={{ width: { xs: '100%' } }} variant="contained">
                Send
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
