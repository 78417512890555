import { Button, CircularProgress, useTheme } from '@mui/material';

export const ApplyFAButton = ({ sending }) => {
  const theme = useTheme();
  return (
    <Button
      sx={{ position: 'relative', height: 40, width: '50%', m: 1, mt: 0 }}
      disabled={sending}
      variant="contained"
      type="submit"
    >
      Send
      {sending && (
        <CircularProgress
          size={22}
          thickness={6}
          color="white"
          sx={{
            color: theme.palette.primary.main,
            position: 'absolute',
            top: '25%',
            left: 'calc(50% - 12px)',
          }}
        />
      )}
    </Button>
  );
};
