import { CircularProgress, Box, useTheme } from '@mui/material';

import { ZoomButton } from './ZoomButton';

export const RegisterRequestButton = ({ sending }) => {
  const theme = useTheme();
  return (
    <Box sx={{ position: 'relative' }}>
      <ZoomButton
        type="submit"
        sx={{ width: '65%', background: sending && 'gray' }}
        disabled={sending}
      >
        Send
      </ZoomButton>
      {sending && (
        <CircularProgress
          size={22}
          thickness={6}
          color="white"
          sx={{
            fontColor: theme.palette.white,
            position: 'absolute',
            top: '25%',
            left: 'calc(50% - 12px)',
          }}
        />
      )}
    </Box>
  );
};
