import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { MenuItem as MuiMenuItem, FormControl, Select, InputLabel, styled } from '@mui/material';

const MenuItem = styled(MuiMenuItem)(() => ({
  minWidth: 100,
}));

const capitalizeText = R.pipe(R.toLower, R.replace(/^./, R.toUpper));

export const SelectUserCountry = ({ countryList, currentCountry: country, setCountry }) => {
  const [countryArr, setCountryArr] = useState([]);

  useEffect(() => {
    const userCountryString = localStorage.getItem('BP_Usercountry');
    const userCountryArr = R.isNil(userCountryString) ? [] : JSON.parse(userCountryString);
    const nonExistCountry = R.without(countryList, [...userCountryArr]);

    const cList =
      userCountryArr.length > 0 && nonExistCountry.length > 0
        ? R.uniq([...countryList, ...nonExistCountry])
        : R.uniq([...countryList]);
    setCountryArr(R.sort((a, b) => a.localeCompare(b), cList));
    setCountry(userCountryArr);
  }, [countryList, setCountry]);

  const handleChangeCountry = (event, child) => {
    const targetValue = child.props.value;
    const selectedList = event.target.value;
    const newCountry =
      targetValue === 'All' && selectedList.includes('All')
        ? ['All']
        : R.without('All', selectedList);
    localStorage.setItem('BP_Usercountry', JSON.stringify(newCountry));
    setCountry(typeof newCountry === 'string' ? newCountry.split(',') : newCountry);
  };

  return (
    <FormControl sx={{ minWidth: 100 }} variant="standard">
      <InputLabel sx={{ paddingLeft: 0.5 }} id="country-select-label">
        Country
      </InputLabel>
      <Select
        labelId="country-select-label"
        variant="standard"
        disableUnderline
        id="select-country"
        multiple
        value={country}
        onChange={handleChangeCountry}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        sx={{
          '& .MuiSelect-select': {
            padding: '4px 0 5px 4px',
          },
        }}
        autoWidth
      >
        <MenuItem
          selected={R.includes('All', countryArr)}
          key="all-country"
          value="All"
          sx={{ padding: 1 }}
        >
          All
        </MenuItem>
        {countryArr.map(
          (cname, index) =>
            cname !== 'All' && (
              <MenuItem
                sx={{ padding: 1 }}
                key={`${index + capitalizeText(cname)}`}
                value={capitalizeText(cname)}
              >
                {cname}
              </MenuItem>
            ),
        )}
      </Select>
    </FormControl>
  );
};

SelectUserCountry.propTypes = {
  countryList: PropTypes.arrayOf(PropTypes.string),
  currentCountry: PropTypes.arrayOf(PropTypes.string),
  setCountry: PropTypes.func,
};
