import { useTheme } from '@mui/material';
import { format, parseISO } from 'date-fns';

import { timeLeft } from '../../../utils/utils';
import { TableNote } from './TableNote';

export const TableTimeLeft = ({ date, daysToShow, daysForWarning }) => {
  const theme = useTheme();
  if (!date) return '';
  const daysLeft = timeLeft(date, true);
  const dateFormatted = format(parseISO(date), 'yyyy-MM-dd');
  const color = daysLeft <= daysForWarning ? theme.palette.error.main : theme.palette.primary.main;
  const text = daysLeft > 1 ? `${daysLeft} days left` : daysLeft > 0 ? 'Last day!' : 'Overdue';
  return (
    <TableNote
      value={dateFormatted}
      note={text}
      sx={{ top: 16, color }}
      display={daysLeft <= daysToShow ? 'block' : 'none'}
    />
  );
};
