import { useMemo } from 'react';

import { useRequest } from './useRequest';
import { getApiUrl } from '../utils';

export const useApplyFA = ({ agreementId, body }) => {
  const endpoint = useMemo(() => `${getApiUrl()}/agreement/${agreementId}/apply`, [agreementId]);
  const formData = new FormData();
  Object.entries(body).forEach(([key, value]) => {
    if (key === 'applicationFiles') {
      value.forEach((file) => {
        formData.append(key, file);
      });
    } else {
      formData.append(key, value);
    }
  });
  return useRequest({
    endpoint,
    method: 'POST',
    externalTrigger: true,
    body: formData,
  });
};
