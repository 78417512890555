import { Navigate } from 'react-router-dom';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

export const Private = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    return <Navigate to="/" />;
  }
  return <> {children} </>;
};
