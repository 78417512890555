import React from 'react';
import { Link } from 'react-router-dom';

import { AccessButton } from './AccessButton';

export const RegisterButton = () => {
  return (
    <AccessButton component={Link} to="/register">
      Register
    </AccessButton>
  );
};
