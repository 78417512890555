import { Tabs as MuiTabs, Tab as MuiTab, styled } from '@mui/material';

export const Tabs = styled(MuiTabs)(() => ({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

export const Tab = styled((props) => <MuiTab disableRipple {...props} />)(({ theme }) => ({
  disableRipple: true,
  padding: 12,
  paddingBottom: 2,
  minWidth: 100,
  background: theme.palette.secondary.main,
  borderTopLeftRadius: 4,
  borderTopRightRadius: 30,
  marginRight: theme.spacing(0.6),
  color: theme.palette.text.primary,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'none',
  transition: 'color 0.3s ease-out, background 0.3s ease-out',
  '&.Mui-selected, &:hover': {
    background: theme.palette.primary.main,
    color: theme.palette.white.main,
  },
}));
