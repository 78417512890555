import { Box, styled, useTheme } from '@mui/material';

export const CategoryButton = styled(Box)(() => {
  const theme = useTheme();
  return {
    background: theme.palette.white.main,
    border: `3px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.4))',
    maxWidth: 200,
    height: 120,
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.2s ease-in-out',
    borderRadius: '12%',
    '&:hover': {
      transform: 'scale(1.075)',
      cursor: 'pointer',
      filter: 'drop-shadow(0px 10px 8px rgba(0,0,0,0.4))',
    },
  };
});
