import { alpha } from '@mui/material';
import { grey } from '@mui/material/colors';

export const theme = {
  palette: {
    mode: 'light',
    primary: { main: '#4B6455' },
    secondary: { main: '#A5B1AA' },
    highlight: { main: '#B7DEBD' },
    lollipop: { main: '#FF00FF' },
    sand: { main: '	#E4E1DB' },
    flamingo: { main: '#FAC0B1', dark: '#e1ada0' },
    melon: { main: '#FCDFD8' },
    white: { main: '#FFFFFF' },
    black: { main: '#000000' },
    leather: { main: '#7C3647' },
    info: { main: grey[300] },
    border: { light: grey[400] },
    text: {
      gray600: grey[600],
      gray700: grey[700],
      gray800: grey[800],
    },
    background: {
      default: '#F1F0ED',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: { overflowX: 'hidden' },
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl',
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          background: alpha('#000000', 0.15),
        },
      },
    },
  },
  typography: {
    h2: {
      fontSize: '4.2rem',
    },
    h3: {
      fontSize: '3.2rem',
      fontWeight: 'bold',
      color: '#000000',
    },
    h4: { fontSize: '2.2rem' },
    h5: { fontSize: '1.6rem' },
    h6: { fontSize: '1.4rem' },
    caption: { fontSize: '0.9rem', color: grey[900] },
    button: {
      textTransform: 'none',
    },
  },
};
