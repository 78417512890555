import { Box, Typography, useTheme } from '@mui/material';
import { LinkOff } from '@mui/icons-material/';

export const NotFound = ({ title, subtitle, icon, children }) => {
  const theme = useTheme();
  return (
    <Box
      container
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        align: 'center',
        textAlign: 'center',
        height: 'calc(100vh - 145px)',
      }}
    >
      {icon || <LinkOff sx={{ width: 100, height: 100, color: theme.palette.primary.main }} />}
      <Typography variant="h5" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Typography>{subtitle}</Typography>
      {children}
    </Box>
  );
};
