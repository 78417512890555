import { Controller } from 'react-hook-form';

import { Dropzone } from './Dropzone';

export const FormFile = ({ name, label, control, setValue, defaultValue = '', labelBackgroud }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange } }) => (
        <Dropzone
          onChange={(e) => onChange(e.target.files[0])}
          {...{ name, label, setValue, labelBackgroud }}
        />
      )}
    />
  );
};
