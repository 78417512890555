import { Link, Typography as MuiTypography, styled } from '@mui/material';

import { appUrl } from '../../utils/utils';

const Typography = styled(MuiTypography)(({ theme }) => ({
  textDecoration: `underline 1px ${theme.palette.lollipop.main}`,
  textUnderlineOffset: '3px',
}));

export const BreadcrumbLink = ({ path, breadcrumb }) => (
  <Link
    underline="none"
    href={appUrl(path)}
    rel="noopener noreferrer"
    onClick={(e) => e.stopPropagation()}
  >
    <Typography variant="caption">{breadcrumb}</Typography>
  </Link>
);
