import { useMemo } from 'react';

import { useRequest } from './useRequest';
import { getApiUrl } from '../utils';

export const useRegister = ({ body }) => {
  const endpoint = useMemo(() => `${getApiUrl()}/applyAccessToBusinessPortal`, []);
  return useRequest({
    endpoint,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    externalTrigger: true,
    includeToken: false,
    body: new URLSearchParams(body),
  });
};
