import * as React from 'react';
import {
  AppBar as MuiAppBar,
  Box,
  Container,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useIsAuthenticated } from '@azure/msal-react';

import { Menu } from './Menu';
import Logo from '../../logo.svg';

export const AppBar = () => {
  const theme = useTheme();
  const mobileMode = useMediaQuery(theme.breakpoints.down('md'));
  const authenticated = useIsAuthenticated();
  return (
    <>
      <MuiAppBar
        position="fixed"
        elevation={0}
        sx={{ height: { xs: 60, md: 90 }, color: 'inherit' }}
      >
        <Container>
          <Toolbar
            disableGutters
            sx={{ height: { xs: 60, md: 90 } }}
            align={mobileMode ? 'center' : 'left'}
          >
            <Box sx={{ flexGrow: 1 }}>
              <img component="img" height={26} alt="" src={Logo} />
            </Box>
            <Menu />
          </Toolbar>
        </Container>
      </MuiAppBar>
      {authenticated && <Toolbar sx={{ height: { xs: 60, md: 90 } }} />}
    </>
  );
};
