import { Chip, Grid } from '@mui/material';
import { People, Remove, Check } from '@mui/icons-material/';

export const StatusChip = ({ status, iconSize, sx }) => {
  const applicant =
    status === 'interested parties' ? 'Others' : status === 'applied' ? 'You' : 'None';
  const color =
    status === 'interested parties' ? 'secondary' : status === 'applied' ? 'primary' : 'info';
  const icon =
    status === 'interested parties' ? (
      <People sx={{ width: iconSize, height: iconSize }} />
    ) : status === 'applied' ? (
      <Check sx={{ width: iconSize, height: iconSize }} />
    ) : (
      <Remove sx={{ width: iconSize, height: iconSize }} />
    );
  return (
    <Chip
      icon={icon}
      label={
        <Grid container sx={{ width: 40 }}>
          <Grid item xs={12}>{`${applicant}`}</Grid>
        </Grid>
      }
      sx={sx}
      color={color}
      size="small"
    />
  );
};
