import { useMemo } from 'react';

import { useRequest } from './useRequest';
import { getApiUrl } from '../utils';

export const useDeal = ({ dealId }) => {
  const endpoint = useMemo(() => `${getApiUrl()}/deals/${dealId}`, [dealId]);
  return useRequest({
    endpoint,
    method: 'GET',
    attachmentPath: ['notes'],
  });
};
