import { useMemo } from 'react';

import { useRequest } from './useRequest';
import { getApiUrl } from '../utils';

export const useAgreement = ({ agreementId }) => {
  const endpoint = useMemo(() => `${getApiUrl()}/agreements/${agreementId}`, [agreementId]);
  return useRequest({
    endpoint,
    method: 'GET',
    attachmentPath: ['frameworkAgreement', 'notes'],
  });
};
