import React, { useRef, useState, useEffect } from 'react';
import { Tooltip as MuiTooltip, Typography, Zoom, tooltipClasses, styled } from '@mui/material';

const Tooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.text.gray800,
    boxShadow: theme.shadows[6],
    fontSize: 12,
  },
  '& .MuiTooltip-arrow': {
    color: theme.palette.white.main,
  },
}));

export const TableTooltip = ({ params }) => {
  const textElementRef = useRef();
  const firstRender = useRef(true);
  const [isOverflowed, setIsOverflow] = useState(false);
  const handleResize = () =>
    setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);

  useEffect(() => {
    if (firstRender) {
      handleResize();
      firstRender.current = false;
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Tooltip
      arrow
      TransitionComponent={Zoom}
      disableHoverListener={!isOverflowed}
      title={
        <>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {params.colDef.headerName}
          </Typography>
          {params.value}
        </>
      }
    >
      <span ref={textElementRef} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {params.value}
      </span>
    </Tooltip>
  );
};
