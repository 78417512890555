export const TableProperties = {
  'properties.customer': 'Customer',
  'properties.dealname': 'Deal name',
  'properties.place': 'Place',
  'properties.lastDayOfTender': 'Last day of tender',
  frameWorkAgreement: 'Framework agreement',
};

export const agreementFormSegments = [
  {
    name: 'Company',
    xs: 12,
    fields: [
      {
        name: 'sharedEmail',
        label: 'E-mail',
        placeholder: 'A shared e-mail for your company',
        required: true,
      },
    ],
  },
  {
    name: 'Reference',
    fields: [
      { name: 'firstName1', label: 'First name', required: true },
      { name: 'lastName1', label: 'Last name', required: true },
      { name: 'role1', label: 'Role', required: true },
      { name: 'email1', label: 'E-mail', required: true },
      { name: 'phone1', label: 'Phone number', required: true },
    ],
  },
  {
    name: 'Secondary reference',
    fields: [
      { name: 'firstName2', label: 'First name' },
      { name: 'lastName2', label: 'Last name' },
      { name: 'role2', label: 'Role' },
      { name: 'email2', label: 'E-mail' },
      { name: 'phone2', label: 'Phone number' },
    ],
  },

  {
    xs: 12,
    name: 'Application files',
    fields: [{ name: 'applicationFiles', label: 'Files' }],
  },
  {
    xs: 12,
    name: '',
    fields: [{ name: 'message', label: 'Leave us a message (optional)', multiline: true, rows: 3 }],
  },
];
