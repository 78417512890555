import { Link, Typography as MuiTypography, styled } from '@mui/material';

import { appUrl } from '../../utils/utils';

const Typography = styled(MuiTypography)(({ theme }) => ({
  textDecoration: `underline 1px ${theme.palette.lollipop.main}`,
  textUnderlineOffset: '4px',
  display: 'inline-block',
  position: 'relative',
  '::before': {
    content: "''",
    position: 'absolute',
    width: 0,
    height: '2px',
    marginTop: 21,
    background: theme.palette.primary.main,
    visibility: 'hidden',
    transition: 'all 0.3s ease-in-out',
  },
  '&:hover::before': {
    visibility: 'visible',
    width: '100%',
  },
}));

export const PageLink = ({ baseUrl = appUrl(), path = '', newTab, sx, children }) => (
  <Link
    underline="none"
    href={`${baseUrl}${path}`}
    target={newTab && '_blank'}
    rel="noopener noreferrer"
    onClick={(e) => e.stopPropagation()}
  >
    <Typography sx={sx} variant="caption">
      {children}
    </Typography>
  </Link>
);
