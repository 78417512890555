import React, { useState } from 'react';
import { Button as MuiButton, styled } from '@mui/material';

const Button = styled(MuiButton)(({ theme }) => ({
  transition: 'border-width 0.25s ease-in-out',
  color: 'black',
  background: theme.palette.white.main,
  border: `1px solid ${theme.palette.primary.main}`,
  disableRipple: true,
  borderRadius: 25,
  textTransform: 'none',
  fontSize: 13,
  padding: 10,
  height: 30,
  flex: 1,
  paddingLeft: 10,
  paddingRight: 10,
  '&:hover': {
    background: theme.palette.white.main,
  },
}));

export const AccessButton = ({ children, ...other }) => {
  const [shadow, setShadow] = useState(7);
  return (
    <Button
      onMouseOver={() => setShadow(1)}
      onMouseOut={() => setShadow(7)}
      sx={{ boxShadow: shadow, transition: 'all 0.15s linear' }}
      {...other}
    >
      {children}
    </Button>
  );
};
