import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import { NotFound as NotFoundWrapper } from '../shared/NotFound';

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <NotFoundWrapper
      title="This page does not exist"
      subtitle="Please check that the link you are trying to use is correct."
    >
      <Button
        sx={{ borderRadius: 20, mt: 2, mb: 1 }}
        variant="contained"
        onClick={() => navigate('/')}
      >
        Go to Home
      </Button>
      <Button sx={{ borderRadius: 20, fontWeight: 'bold' }} onClick={() => navigate(-1)}>
        Return to your previous page
      </Button>
    </NotFoundWrapper>
  );
};
