import { useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as R from 'ramda';
import { Typography, Box, Container, Stack, Divider, useMediaQuery, useTheme } from '@mui/material';
import { QuestionAnswer, Mail, MenuBook } from '@mui/icons-material/';

import { CategoryButton } from './CategoryButton';
import { Faq } from './Faq';
import { Contact } from './Contact';
import { Guide } from './Guide';
import { ColorBanner } from '../../shared/ColorBanner';

export const Support = () => {
  const theme = useTheme();
  const mobileMode = useMediaQuery(theme.breakpoints.down('md'));
  const categories = [
    {
      title: 'FAQ',
      icon: <QuestionAnswer sx={{ width: 28, height: 28, color: theme.palette.primary.main }} />,
      content: <Faq />,
      ref: useRef(null),
    },
    {
      title: 'Contact',
      icon: <Mail sx={{ width: 28, height: 28, color: theme.palette.primary.main }} />,
      content: <Contact />,
      background: theme.palette.white.main,
      ref: useRef(null),
    },
    {
      title: 'User guide',
      icon: <MenuBook sx={{ width: 28, height: 28, color: theme.palette.primary.main }} />,
      content: <Guide />,
      ref: useRef(null),
    },
  ];

  const scrollToCategory = (index) => {
    if (categories[index].ref.current) {
      window.scrollTo({
        top: categories[index].ref.current.getBoundingClientRect().top - 30,
        behavior: 'smooth',
      });
    }
  };

  const { section } = useParams();

  useEffect(() => {
    if (section) {
      const index = R.findIndex(
        R.propEq('title', section.charAt(0).toUpperCase() + section.slice(1)),
      )(categories);
      scrollToCategory(index);
    }
  });

  return (
    <>
      <ColorBanner
        sx={{
          height: mobileMode ? 200 : 250,
          textAlign: 'center',
          borderBottomLeftRadius: '15%',
          borderBottomRightRadius: '15%',
        }}
        title=<Box sx={{ mt: 2 }}>Support center</Box>
        subtitle="How can we help you?"
      />
      <Box container display="flex" justifyContent="center">
        <Stack direction="row" spacing={2} sx={{ justifyContent: 'center', mt: '-80px', flex: 1 }}>
          {categories.map((category, index) => (
            <CategoryButton key={category.title} onClick={() => scrollToCategory(index)}>
              <Stack alignItems="center">
                {category.icon}
                <Typography variant="h6" sx={{ position: 'relative', fontWeight: 'bold' }}>
                  {category.title}
                </Typography>
              </Stack>
            </CategoryButton>
          ))}
        </Stack>
      </Box>

      <Typography sx={{ fontWeight: 'bold', mt: 10, ml: 1 }}>
        Welcome to the support center for Knowit Business Portal
      </Typography>
      <Typography sx={{ mt: 2, ml: 1 }}>
        Problems using the site? Whether it&apos;s technical or other kinds of problems, we can help
        you.
      </Typography>
      <Divider sx={{ mt: 2, mb: -6 }} />

      {categories.map((category) => (
        <div ref={category.ref} key={category.title}>
          <ColorBanner
            color={category.color || theme.palette.black.main}
            sx={{
              background: category.background || 'inherit',
              p: 2,
              pt: 12,
              pb: 12,
              mb: 0,
            }}
            disableGutters
          >
            <Container>
              <Stack direction="row" sx={{ display: 'flex', mb: 4, alignItems: 'center' }}>
                <Typography variant="h5" sx={{ mr: 1 }}>
                  {category.title}
                </Typography>
                {category.icon}
              </Stack>
              {category.content}
            </Container>
          </ColorBanner>
        </div>
      ))}
    </>
  );
};
