import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { CircularProgress, Box, useTheme } from '@mui/material';
import { DoDisturbAlt } from '@mui/icons-material/';

import { useCancelDeal } from '../../../utils/hooks/useCancelDeal';
import { ChipButton } from './ChipButton';

export const CancelDealButton = ({ dealId, onSuccess, onError }) => {
  const theme = useTheme();
  const width = 215;
  const [canceled, setCanceled] = useState(false);
  const { loading: canceling, result, triggerFunc } = useCancelDeal({ dealId });

  useEffect(() => {
    if (!R.isEmpty(result)) {
      const { ok, status: responseStatus, statusText } = result;
      if (!ok && onError)
        onError(`Failed to cancel your application: ${responseStatus} ${statusText}`);
      else if (ok === true) {
        setCanceled(true);
        if (onSuccess) onSuccess();
      }
    }
  }, [result, onSuccess, onError]);

  return canceled ? (
    <ChipButton
      text="Your application was canceled"
      icon={<DoDisturbAlt />}
      width={width}
      sx={{
        mb: 4,
        mt: 4,
        pointerEvents: 'none',
        background: theme.palette.text.gray600,
      }}
    />
  ) : (
    <Box sx={{ position: 'relative', mb: 4, mt: 4, width, height: 40 }}>
      <ChipButton
        text={canceling ? 'Canceling application' : 'You applied for this assignment'}
        hoverText={canceled ? '' : 'Cancel your application'}
        width={width}
        onClick={triggerFunc}
        disabled={canceling}
        disableIcon={canceling}
      />
      {canceling && (
        <CircularProgress
          size={22}
          thickness={6}
          sx={{
            position: 'absolute',
            top: '25%',
            left: '58%',
          }}
        />
      )}
    </Box>
  );
};

CancelDealButton.propTypes = {
  dealId: PropTypes.number,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};
