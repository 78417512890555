import { Button, styled } from '@mui/material';

export const ZoomButton = styled(Button)(({ theme }) => ({
  transition: 'all 0.25s ease-in-out',
  color: 'white',
  background: theme.palette.primary.main,
  disableRipple: true,
  borderRadius: 30,
  textTransform: 'none',
  fontSize: 16,
  padding: 10,
  height: 42,
  flex: 1,
  paddingLeft: 10,
  paddingRight: 10,
  '&:hover': {
    background: theme.palette.primary.main,
    color: 'white',
    transform: 'scale(1.05)',
  },
}));
