import { useMemo } from 'react';

import { useRequest } from './useRequest';
import { getApiUrl } from '../utils';

export const useCancelFA = ({ agreementId }) => {
  const endpoint = useMemo(() => `${getApiUrl()}/agreement/${agreementId}/cancel`, [agreementId]);
  return useRequest({
    endpoint,
    method: 'GET',
    externalTrigger: true,
  });
};
