export const availableFAColumns = [
  { field: 'name', headerName: 'Name', minWidth: 120, maxWidth: 450, flex: 1 },
  { field: 'frameworkAgreementId', headerName: 'ID', width: 180 },
  {
    field: 'contractingPartyCustomer',
    headerName: 'Customer',
    minWidth: 120,
    maxWidth: 220,
    flex: 1,
  },
];
