import { Container, Typography, Grid, Stack, Card, Box, useTheme } from '@mui/material';

import { SignInButton } from './SignInButton';
import { RegisterButton } from './RegisterButton';
import { ImageBanner } from '../../shared/ImageBanner';
import { PageLink } from '../../shared/PageLink';

import Image from '../../../images/windows.jpg';

export const HomeGuest = () => {
  const theme = useTheme();
  return (
    <ImageBanner image={Image} height="100vh" surface="rgba(255,255,255,0.84)">
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: true,
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: theme.palette.text.gray800,
              mt: { xs: 8, md: 12, lg: 12 },
              p: { xs: 2, md: 6, lg: 12 },
            }}
          >
            Knowit Business Portal
          </Typography>

          <Card
            sx={{
              width: 360,
              height: 550,
              background: theme.palette.white.main,
            }}
            elevation={20}
          >
            <Grid
              container
              direction="column"
              sx={{
                height: '100%',
              }}
            >
              <Grid
                item
                xs={3}
                sx={{
                  background: theme.palette.primary.main,
                  width: '84%',
                  borderBottomRightRadius: '100%',
                }}
              >
                <Typography
                  variant="h5"
                  color={theme.palette.white.main}
                  sx={{ mt: 5.5, ml: 3, mb: 1 }}
                  align="left"
                >
                  Welcome
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Stack direction="column" sx={{ m: 6, mt: 5 }} spacing={theme.spacing(1.5)}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Authentication required
                  </Typography>
                  <SignInButton />
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack direction="column" sx={{ m: 6, mt: -9 }} spacing={theme.spacing(1.5)}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Not a member?
                  </Typography>
                  <RegisterButton />
                </Stack>
              </Grid>
              <Grid item xs={1} align="left">
                <PageLink path="/support" sx={{ ml: 4 }}>
                  Need help?
                </PageLink>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Container>
    </ImageBanner>
  );
};
