import { Typography, useTheme } from '@mui/material';

export const Guide = () => {
  const theme = useTheme();
  return (
    <>
      <Typography sx={{ mb: 2, mt: 0, color: theme.palette.black.main }}>
        This is the portal with the aim of matching business opportunities and offers with each
        other. You are welcome to do business, and here you can reach a lot of Knowit´s
        opportunities from our framework agreements with some of our customers.
      </Typography>
      <Typography sx={{ mb: 2, mt: 0, color: theme.palette.black.main }}>
        Here is everything you need to know to apply for a framework agreement and start looking for
        assignments. Before you can browse among our assignments you need to apply for a framework
        agreement. You can see open assignments for the framework agreements that you have applied
        and have been approved for. The assignments you will see are the ones that you can apply
        for. When last day of tender is passed, the assignment is moved to archive automatically.
        For all users in business portal an email will be sent in end of business day that shows the
        title and customer for the new assignments that have been received and registered in
        business portal. If we get more than one applicant, we work with the company that has the
        greatest chance of winning an assignment, a combination of fulfillment of requirements and
        prize.
      </Typography>
      <Typography sx={{ mb: 2, mt: 0, color: theme.palette.black.main }}>
        The purpose of this portal is to be used by sales staff in Knowit and at our partners in
        whole group, both for public and private market. The first step is to launch it in Sweden
        for public framework agreements. Will continue with partners and then other framework
        agreements and other countries in Knowit. Read more at{' '}
        <a href="https://knowit.se/ramavtal">knowit.se/ramavtal</a> (swedish).
      </Typography>
    </>
  );
};
