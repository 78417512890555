import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { FormControlLabel, Switch, Typography, useTheme } from '@mui/material';

import { SearchField } from './SearchField';

export const TableToolbar = ({
  setSearchText,
  onSwitchChange,
  filterSwitchSearchText,
  filterSwitchLabel,
}) => {
  const theme = useTheme();
  return (
    <GridToolbarContainer sx={{ pt: 1, pb: 2 }}>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector sx={{ display: { xs: 'none', md: 'inherit' } }} />
      <GridToolbarFilterButton sx={{ display: { xs: 'none', md: 'inherit' } }} />
      {filterSwitchSearchText && (
        <FormControlLabel
          control={<Switch size="small" onChange={onSwitchChange} />}
          sx={{ ml: 1 }}
          label={
            <Typography sx={{ fontSize: 13, color: theme.palette.primary.main }}>
              {filterSwitchLabel}
            </Typography>
          }
        />
      )}
      <SearchField {...{ setSearchText }} sx={{ mr: 1 }} />
    </GridToolbarContainer>
  );
};
