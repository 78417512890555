import { Typography, Divider } from '@mui/material';
import { Info } from '@mui/icons-material';

export const Notice = ({ message, color = 'primary' }) => (
  <>
    <Typography variant="body2" sx={{ mb: 2 }}>
      <Info sx={{ mb: -0.8, mr: 1, color }} />
      {message}
    </Typography>
    <Divider sx={{ width: '100vw', mb: 2, marginLeft: 'calc(50% - 50vw)' }} />
  </>
);
