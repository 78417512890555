import { Box, useTheme } from '@mui/material';

export const TableNote = ({ value, note, display = 'block', sx }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        width: '100%',
        textOverflow: 'ellipsis',
      }}
    >
      <Box
        display={display}
        sx={{
          position: 'absolute',
          transform: 'translate(0, -10%)',
          fontSize: 10.5,
          color: theme.palette.error.main,
          top: 16,
          right: 0,
          height: 14,
          p: 0.3,
          borderRadius: 2,
          width: '100%',

          ...sx,
        }}
      >
        {note}
      </Box>
      {value}
    </Box>
  );
};
