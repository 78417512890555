import { Box, useTheme, useMediaQuery } from '@mui/material';

export const Background = ({
  image,
  height,
  variant = 'fullscreen',
  surface = 'linear-gradient(275deg, rgba(0,0,0,0) 0%, rgba(228,225,219,1) 35%)',
  sx,
  fixed,
  background,
  children,
}) => {
  const theme = useTheme();
  const mobileMode = useMediaQuery(theme.breakpoints.down('md'));

  const imageWidth = variant === 'right' || variant === 'left' ? '50%' : '100%';
  const imageRight = variant === 'right' ? 0 : 'none';
  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          height: '100vh',
          width: '100vw',
          background,
        }}
      />
      <Box
        sx={{
          height,
          width: '100vw',
          marginLeft: 'calc(50% - 50vw)',
          position: 'relative',
          ...sx,
        }}
      >
        <Box
          sx={{
            backgroundImage: !mobileMode && `url(${image})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '100%',
            width: imageWidth,
            right: imageRight,
            position: fixed ? 'fixed' : 'absolute',
          }}
        >
          <Box
            sx={{
              background: surface,
              height: '100%',
            }}
          />
        </Box>
        <Box
          sx={{
            height: '100vh',
            position: 'absolute',
            width: '100%',
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};
