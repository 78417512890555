import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { Table } from '../../shared/table/Table';
import { useAgreements } from '../../../utils/hooks/useAgreements';

export const FATableView = ({ columns, onlyApproved = false, selectedCountry, setCountryList }) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const { loading, result = {}, error } = useAgreements(onlyApproved ? { onlyApproved } : {});
  const { results: deals = [], total } = result;

  const filterDealsByCountry = useMemo(
    () => deals.filter((deal) => selectedCountry.includes(deal.country)),
    [deals, selectedCountry],
  );

  useEffect(() => {
    const countryList = R.reject(
      R.either(R.isEmpty, R.isNil),
      R.uniq(R.map(R.prop('country'))(deals)),
    );
    if (deals.length > 0) setCountryList([...countryList]);
  }, [deals, setCountryList]);

  return (
    <Table
      data={R.sortWith([R.descend(R.prop('created'))])(
        selectedCountry.includes('All') || selectedCountry.length === 0
          ? deals
          : filterDealsByCountry,
      )}
      cols={columns}
      error={error}
      page={page}
      setPage={setPage}
      size={pageSize}
      setSize={setPageSize}
      loading={loading}
      getRowId={(row) => row.hubspotId}
      initialState={{
        pagination: {
          pageSize: 50,
        },
        sorting: {
          sortModel: [{ field: 'name', sort: 'asc' }], // Will not work for more than 50 FAs
        },
      }}
      clickable
    />
  );
};

FATableView.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  selectedCountry: PropTypes.arrayOf(PropTypes.string),
  setCountryList: PropTypes.func,
};
