import { appUrl } from '../utils/utils';

const tenantId = '6735929c-9dbf-473b-9fc6-5fbdcd2c9fc4';
const clientId = '319607cb-10aa-4048-96c0-a73f5a5a577e';

export const msalConfig = {
  auth: {
    clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: appUrl(),
    postLogoutRedirectUri: appUrl(),
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
  redirectUri: appUrl(),
};
