import { Typography, Box, Zoom, Grow, useTheme } from '@mui/material';
import { CheckCircleOutlined } from '@mui/icons-material/';

export const RegisteredNotice = ({ sent, width }) => {
  const theme = useTheme();
  return (
    <Box sx={{ maxWidth: width }} display={sent ? 'block' : 'none'}>
      <Zoom in={sent} timeout={1000}>
        <CheckCircleOutlined sx={{ width: 100, height: 100, color: theme.palette.primary.main }} />
      </Zoom>
      <Grow in={sent} timeout={800}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Your registration was successful
        </Typography>
      </Grow>
      <Grow in={sent} timeout={1000}>
        <Typography variant="body2">
          Before you can sign in, your details will be reviewed.
        </Typography>
      </Grow>
    </Box>
  );
};
