import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';

export const ErrorDialog = ({ error, open, setOpen }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [copied, setCopied] = useState(false);
  const copyError = () => {
    setCopied(true);
    return window.navigator.clipboard.writeText(error.message);
  };

  const handleClose = () => {
    setOpen(false);
    setCopied(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle display="flex" alignItems="center" sx={{ pb: 1, mb: 1 }}>
        {`${error.response.status} ${error.response.statusText}`}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ position: 'relative' }}>
          <Typography
            variant="body2"
            sx={{
              mb: 2,
              fontSize: 12,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 2,
              p: 2,
            }}
          >
            {error.message}
          </Typography>

          <Button
            size="small"
            sx={{ fontSize: 12, position: 'absolute', right: 0, bottom: 0 }}
            onClick={copyError}
          >
            {copied ? 'Copied \u2713' : `Copy`}
          </Button>
        </Box>
        <Typography
          sx={{
            fontSize: 12,
          }}
        >
          Contact support and refer to this error message if you need help solving this problem.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ mt: -3 }}>
        <Button onClick={() => navigate('/support')} autoFocus>
          Help
        </Button>
        <Button onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
