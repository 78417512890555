import { Box } from '@mui/material';

export const ImageBanner = ({
  image,
  height,
  surface = 'linear-gradient(275deg, rgba(0,0,0,0) 0%, rgba(228,225,219,1) 35%)',
  children,
}) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${image})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height,
        borderRadius: 0,
        width: '100vw',
        marginLeft: 'calc(50% - 50vw)',
      }}
      elevation={0}
    >
      <Box
        sx={{
          background: surface,
          height: '100%',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
