import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Grid, Stack, Grow, useTheme } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import * as R from 'ramda';

import { InfoCard } from './InfoCard';
import { ImageCard } from './ImageCard';
import { ImageBanner } from '../../shared/ImageBanner';
import { NavigateButton } from '../../shared/NavigateButton';
import { PageLink } from '../../shared/PageLink';
import { appUrl } from '../../../utils/utils';

import BackgroundImage from '../../../images/stockholm-downsized.jpg';
import CardImage from '../../../images/knowit-people.jpg';

const infoCards = [
  {
    title: 'Support',
    description:
      'Here is everything you need to know to apply for a framework agreement and start looking for assignments.',
    navigate: 'support',
  },
  {
    title: 'Message us',
    description: (
      <Typography>
        Ask us anything. Wheter it&apos;s techical or other kinds of problems, we can help you.{' '}
        <a href="mailto: businessportal@knowit.eu">businessportal@knowit.eu</a>
      </Typography>
    ),
    navigationText: 'Contact us',
    navigate: 'support/contact',
  },
];

const capitalizeText = R.pipe(R.toLower, R.replace(/^./, R.toUpper));

export const Home = () => {
  const navigate = useNavigate();
  const { accounts } = useMsal();
  const name = accounts[0] && accounts[0].name;
  const theme = useTheme();

  const getUserCountry = () =>
    fetch('https://api.hostip.info/get_json.php')
      .then((res) => res.json())
      .then((response) => {
        const { country_name: countryname } = response;
        if (R.and(R.not(R.isNil(countryname)), R.not(R.includes('?', countryname)))) {
          const formattedCountry = capitalizeText(countryname);
          localStorage.setItem('BP_Usercountry', JSON.stringify([formattedCountry]));
        }
      })
      .catch((error) => {
        console.error(error);
      });

  useEffect(() => {
    const usercountry = localStorage.getItem('BP_Usercountry');
    if (!usercountry) getUserCountry();
  }, []);

  return (
    <>
      <ImageBanner
        image={BackgroundImage}
        height={{ xs: 440, md: 575 }}
        surface="linear-gradient(275deg, rgba(0,0,0,0) -15%, rgba(255,255,255,1) 100%)"
      >
        <Container>
          <Grid container alignItems="center" sx={{ height: { xs: 440, md: 575 } }}>
            <Grid item xs={12} sx={{}}>
              <Grid sx={{ mt: 2 }}>
                <Grow in timeout={600}>
                  <Typography variant="h4" color="text.highlight">
                    {name ? `Hi ${name.replace(/ .*/, '')}, welcome to` : 'Welcome to'}
                  </Typography>
                </Grow>
                <Grow in timeout={1200}>
                  <Typography variant="h2" sx={{ mt: 2, mb: 1.5 }}>
                    Knowit{' '}
                    <span
                      style={{
                        textDecoration: `underline 3px ${theme.palette.primary.main}`,
                        textUnderlineOffset: '4px',
                      }}
                    >
                      Business
                    </span>{' '}
                    Portal
                  </Typography>
                </Grow>
                <Grow in timeout={1800}>
                  <Stack direction="row" spacing={4} sx={{ mt: 6 }}>
                    <NavigateButton to={appUrl('/framework-agreements')}>
                      Framework Agreements
                    </NavigateButton>
                    <NavigateButton to={appUrl('/assignments')}>Assignments</NavigateButton>
                  </Stack>
                </Grow>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </ImageBanner>

      <Grid container justifyContent="left" spacing={theme.spacing(4)} sx={{ mt: 2 }}>
        <Grid item md={12} lg={6}>
          <ImageCard
            title="Our framework agreements"
            description="Before you can browse amongst our assignments you need to apply for a framework agreement."
            image={CardImage}
            action={
              <PageLink
                baseUrl="https://www.knowit.se/"
                path="partner/ramavtal/"
                newTab
                sx={{ marginTop: 2 }}
              >
                Read more at Knowit (swedish)
              </PageLink>
            }
            sx={{ background: theme.palette.sand.main }}
            elevation={6}
          />
        </Grid>
        {infoCards.map((item) => (
          <Grid item key={item.title} xs={12} md={3}>
            <InfoCard
              title={item.title}
              description={item.description}
              navigationText={item.navigationText}
              onClick={() => item.navigate && navigate(item.navigate)}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
