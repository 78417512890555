import { Typography, Grid, Divider, useTheme, Link } from '@mui/material';
import { FormText } from '../../shared/form-fields/FormText';
import { FormCheckbox } from '../../shared/form-fields/FormCheckbox';

export const RegisterForm = ({ control }) => {
  const formSegments = [
    {
      name: 'You',
      fields: [
        { name: 'firstName', label: 'First name' },
        { name: 'lastName', label: 'Last name' },
        { name: 'phone', label: 'Phone number' },
        { name: 'email', label: 'E-mail', type: 'email' },
      ],
    },
    {
      name: 'Your company',
      fields: [
        { name: 'companyName', label: 'Name' },
        { name: 'companyPhone', label: 'Phone number' },
        {
          name: 'companyOrganizationNumber',
          label: 'Organization number',
        },
      ],
    },
  ];

  const theme = useTheme();
  return (
    <>
      {formSegments.map((segment) => (
        <Grid key={segment.name} container item xs={12} sx={{ mb: 3 }}>
          <Grid item align="left" xs={12} sx={{ mb: 0 }}>
            <Divider sx={{ mb: 2, color: theme.palette.text.gray600 }}>{segment.name}</Divider>
          </Grid>
          <Grid container item xs={12}>
            {segment.fields.map((field) => (
              <Grid item key={field.name} xs={12} sx={{ mb: 2 }}>
                <FormText
                  {...field}
                  control={control}
                  sx={{ background: theme.palette.white.main }}
                  required
                  InputLabelProps={{ required: false }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} sx={{ mb: 4 }}>
        <FormCheckbox
          label="gdprConsent"
          control={control}
          sx={{ background: theme.palette.white.main }}
          required
          size="small"
        />
        <Typography variant="caption" sx={{ fontSize: 10 }}>
          By registering as a user in the Business Portal, I confirm that I have read and approved
          Knowit&apos;s{' '}
          <Link href="https://www.knowit.se/globalassets/policies/kab_0239_eng_external_privacy_policy.pdf">
            External Privacy Policy
          </Link>
        </Typography>
      </Grid>
    </>
  );
};
